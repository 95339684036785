/**
  * название функции
  *
  * @param {number} first - первое число
  * @returns {number}
  */
 document.addEventListener( 'DOMContentLoaded', function () {
  new Splide( '#brends-slider', {
		fixedWidth: 100,
		gap       : 10,
		rewind    : true,
		pagination: false,
    isNaigation: false,
    arrows: false,
  } ).mount();

  // валидация формы 
  const form = document.querySelector('.form');
  //  inputmask
  const telSelector = form.querySelector('input[type="tel"]');
  const inputMusk = new Inputmask('+7 (999) 999-99-99');
  inputMusk.mask(telSelector);

  new window.JustValidate('.form', {
    rules: {
      tel: {
        required: true,
        function: () => {
          const phone = telSelector.inputmask.unmaskedvalue();
          return Number(phone) && phone.length === 10;
        }
      }
    },
    messages: {
      name: {
        required: 'Введите имя',
        minLength: 'Имя должно быть от 3 до 15 символов',
        maxLength: 'Имя дожно быть от 3 до 15 символов'
      },
      email: {
        email: 'Введите корректный email',
        required: 'Введите email'
      },
      tel: {
        required: 'Введите телефон',
        function: 'Телефон должен содержать 10 символов'
      }
    },
    submitHandler: function (thisForm){
      let formData = new FormData(thisForm);

      let xhr = new XMLHttpRequest();
      
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // console.log('Отправлено');
            modal.style.display = "block";
          }
        }
      }

      xhr.open('POST', 'mail.php', true);
      xhr.send(formData);

      thisForm.reset();
    }
  });

  // Обработка поведения модального окна
  var modal = document.getElementById("my_modal");
  var span = document.getElementsByClassName("close_modal_window")[0];
 
  span.onclick = function () {
     modal.style.display = "none";
  }
 
  window.onclick = function (event) {
     if (event.target == modal) {
         modal.style.display = "none";
     }
  }
 
} );

